<template>
  <div class="header mb-5">
    <v-app-bar app color="transparent" flat height="75">
      <v-container class="py-0 fill-height">
        <div class="d-flex align-center">
          <v-btn
            fab
            x-small
            color="primary"
            elevation="1"
            @click="$store.state.menu = !$store.state.menu"
          >
            <v-icon>
              {{ !$store.state.menu ? "format_list_bulleted" : "more_vert" }}
            </v-icon>
          </v-btn>
        </div>
        <v-spacer> </v-spacer>
        <img width="120" class="logo" src="@/assets/logo.png" />
        <v-spacer> </v-spacer>
        <div class="d-flex align-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="rounded-lg header-menu btn-drop-down black--text align-center d-block justify-start"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <img
                  width="50"
                  class="rounded-circle"
                  src="https://placeimg.com/50/50/nature?any?1"
                />
                <div class="d-none d-md-block">
                  <h4 class="black--text ms-2">المدير</h4>
                </div>
                <v-icon class="d-none d-md-block" right>
                  keyboard_arrow_down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense class="menu-no-active">
              <v-list-item-group color="primary">
                <v-list-item link to="/settings">
                  <v-list-item-icon class="me-1">
                    <v-icon>settings</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>الاعدادت</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-icon class="me-1">
                    <v-icon>logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>تسجيل الخروج </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!--/menu -->
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .language {
    width: 50px;
    font-size: 13px;
    .v-select__selections {
      font-weight: bold;
      font-size: 14px;
      padding: 0;
    }
    fieldset {
      border: none !important;
    }
    .v-input__icon {
      display: none;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
  }
  //---- language

  .lang-item {
    min-height: 28px !important;
    font-size: 12px !important;
  }
}
</style>
<script>
export default {
  name: "AppHeader",
  data: () => ({
    lang: {
      val: {
        name: "Arbic",
        shortCut: "Ar",
        icon: "ar.png",
      },
      langs: [
        {
          id: 1,
          name: "Arbic",
          shortCut: "Ar",
          icon: "ar.png",
        },
        {
          id: 2,
          name: "English",
          shortCut: "En",
          icon: "en.png",
        },
      ],
    },
    items: [
      { text: "البروفيل", icon: "mdi-account" },
      { text: "تسجيل الخروج", icon: "logout" },
    ],
  }),
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      let vm = this;
      this.$store.commit("logout");
      vm.$router.push({ name: "login" });
    },
  },
  computed: {},
};
</script>
